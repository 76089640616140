import React, { createContext, useState, useContext, useCallback } from 'react';

const TextContext = createContext();

const fetchTexts = async () => {
    const response = await fetch('/texts.json');
    if (!response.ok) {
        throw new Error('Failed to load texts');
    }
    const data = await response.json();
    return data;
};

export const TextProvider = ({ children }) => {
    const [texts, setTexts] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const loadTexts = useCallback(async () => {
        setIsLoading(true);
        try {
            const loadedTexts = await fetchTexts();
            setTexts(loadedTexts);

            if (process.env.DEBUG) {
                console.log('Loaded texts:', loadedTexts);
            }
        } catch (error) {
            if (process.env.DEBUG) {
                console.error('Error loading texts:', error);
            }
        } finally {
            setIsLoading(false);
        }
    }, []);

    return (
        <TextContext.Provider value={{ texts, isLoading, loadTexts }}>
            {children}
        </TextContext.Provider>
    );
};

export const useTexts = () => {
    return useContext(TextContext);
};
