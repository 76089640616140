import React from "react";
import { Box, Typography, IconButton } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const CopyableText = ({ text, label }) => {
    const handleCopy = () => {
        navigator.clipboard.writeText(text);
    };

    return (
        <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
            <Typography variant="body1">
                {label}:{'\u00A0'}
                <Typography onClick={handleCopy} variant="subtitle1" component="span" sx={{
                    display: "inline",
                    cursor: "pointer"
                }}>
                    {text}
                </Typography>
            </Typography>
            <IconButton onClick={handleCopy}>
                <ContentCopyIcon />
            </IconButton>
        </Box>
    );
};

export default CopyableText;
