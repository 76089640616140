import {createTheme} from '@mui/material/styles';


const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#800020',
        },
        secondary: {
            main: '#800020',
        },
        text: {
            primary: '#fff',
        },
        background: {
            default: '#000',
            paper: "#000"
        },
        border: {
            modal: "#fff"
        }
    },
    typography: {
        fontFamily: 'Montserrat, sans-serif',
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    boxSizing: 'border-box',
                },
                '*': {
                    boxSizing: 'border-box',
                },
                '*::before': {
                    boxSizing: 'border-box',
                },
                '*::after': {
                    boxSizing: 'border-box',
                },
                "::-webkit-scrollbar": {
                    width: "5px",
                },
                "::-webkit-scrollbar-track": {
                    background: "rgba(0.0.0.0)",
                },
                "::-webkit-scrollbar-thumb": {
                    background: "#800020",
                },
            },
        },
        MuiStack: {
            styleOverrides: {
                root: ({ theme }) => ({
                    "&[data-paper]": {
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        top: 0,
                        zIndex: 2,
                        cursor: "pointer",
                        padding: "2rem",
                        transition: "all .5s cubic-bezier(.42, 0, 0, .995)",
                        [theme.breakpoints.up("md")]: {
                            width: "50%",
                        },
                    },
                    "&[data-window]": {
                        width: "100%",
                        minHeight: "20rem",
                        border: "0.0625rem solid #800020",
                        padding: "2rem",
                        background: "#000"
                    }
                })
            }
        },
        MuiModal: {
            styleOverrides: {
                root: {
                    "& .MuiStack-root": {
                        width: "max-content",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        backgroundColor: "#000",
                        border: "0.125rem solid #fff",
                        padding: "2rem",
                    }
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    '&[data-info]': {
                        padding: "2rem",
                        background: "#000",
                        border: "0.0625rem solid #800020",
                    },
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    color: "#800020",
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 0,
                    "&.Mui-disabled": {
                        color: "inherit",
                        backgroundColor: "rgb(81, 9, 23)"
                    }
                },
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    color: "#800020",
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#fff",
                        borderWidth: "1px"
                    },
                },
                notchedOutline: {
                    borderColor: "#800020",
                    borderRadius: 0,
                    "&:hover": {
                        borderColor: "#fff",
                    }
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: "#fff",
                    "&.Mui-focused": {
                        color: "#fff",
                    },
                    "&:hover": {
                        color: "#fff",
                    },
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    "&.MuiMenu-paper": {
                        backgroundColor: "#000",
                        borderRadius: 0,
                    },
                },
            },
        },
    },
});


export {theme};
