import React, {useState} from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Grid, Paper, Typography, Accordion, AccordionSummary, AccordionDetails, List, ListItem, ListItemText} from "@mui/material";
import { Helmet, HelmetProvider } from "react-helmet-async";

import Particles from "./Particles";


const WGGuide = () => {
    const [expanded, setExpanded] = useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <HelmetProvider>
            <Helmet>
                <title>WireGuard - Обход блокировки РНК для WireGuard</title>
                <meta name="description" content="Обход блокировки РНК для WireGuard"/>
                <meta name="keywords" content="WireGuard, Обход РНК, РНК, Блокировка РНК"/>
            </Helmet>
            <Particles/>
            <Grid container justifyContent="center" alignItems="center" sx={{height: "100%"}}>
                <Grid style={{
                    width: "95%",
                    height: "95%"
                }}>
                    <Typography data-info component="div" style={{
                        height: "100%",
                        overflow: "auto",
                    }}>
                        <Typography variant="h4" component="h1" mb={4}>Обход блокировки РНК для WireGuard</Typography>

                        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <strong>Windows</strong>
                            </AccordionSummary>
                            <AccordionDetails>
                                <List>
                                    <ListItem>
                                        <ListItemText>
                                            1. Откройте PowerShell от имени администратора: через поиск или <code>Win +
                                            X</code>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText>
                                            2. Введите следующие команды в PowerShell:
                                            <Paper
                                                elevation={3}
                                                style={{
                                                    padding: '0 1rem',
                                                    overflowX: 'auto',
                                                    marginTop: "1rem",
                                                }}
                                            >
                                            <pre>
                                                <code>
                                                    $wgListenPort = 56120  # Укажите любой доступный порт, например, 56120{'\n'}
                                                    $wgIP = "88.218.93.227"{'\n'}
                                                    $wgPORT = 56856{'\n'}
                                                    {'\n'}
                                                    $EndPoints = New-Object System.Net.IPEndPoint([System.Net.IPAddress]::Parse([System.Net.Dns]::GetHostAddresses($wgIP)), $wgPORT){'\n'}
                                                    $Socket = New-Object System.Net.Sockets.UDPClient $wgListenPort{'\n'}
                                                    $SendMessage = $Socket.Send([Text.Encoding]::ASCII.GetBytes(":)"), 2, $EndPoints){'\n'}
                                                    $Socket.Close(){'\n'}
                                                </code>
                                            </pre>
                                            </Paper>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText>
                                            3. В конфигурационном файле WireGuard под <code>[Interface]</code> добавьте или
                                            измените строку:
                                            <Paper
                                                elevation={3}
                                                style={{
                                                    padding: '0 1rem',
                                                    overflowX: 'auto',
                                                    marginTop: "1rem",
                                                }}
                                            >
                                                <pre><code>ListenPort = 56120</code></pre>
                                            </Paper>
                                        </ListItemText>
                                    </ListItem>
                                </List>
                                <Typography><strong>Замечание: При каждом отключении VPN необходимо повторно выполнить
                                    вышеуказанные шаги для восстановления соединения.</strong></Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel2-content"
                                id="panel2-header"
                            >
                                <strong>Linux</strong>
                            </AccordionSummary>
                            <AccordionDetails>

                            <List>
                                    <ListItem>
                                        <ListItemText>
                                            1. Установите <code>nmap</code>: <pre><code>sudo apt install nmap</code></pre>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText>
                                            2. В конфигурационном файле WireGuard под <code>[Interface]</code> добавьте или измените строки:
                                            <Paper
                                                elevation={3}
                                                style={{
                                                    padding: '0 1rem',
                                                    overflowX: 'auto',
                                                    marginTop: "1rem",
                                                }}
                                            >
                                                <pre><code>ListenPort = 56120</code></pre>
                                                <pre><code>PreUp = nping --udp --count 1 --data-length 16 --source-port 56120 --dest-port 56856 88.218.93.227</code></pre>
                                            </Paper>
                                        </ListItemText>
                                    </ListItem>
                                </List>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel3-content"
                                id="panel3-header"
                            >
                                <strong>IOS & Android</strong>
                            </AccordionSummary>
                            <AccordionDetails>Скоро будет доступно!</AccordionDetails>
                        </Accordion>
                    </Typography>
                </Grid>
            </Grid>
        </HelmetProvider>
    );
};

export default WGGuide ;
