import React, { useState, useEffect } from "react";
import { Modal, Stack, Typography, Button } from "@mui/material";

import CopyableText from "./CopyableText";
import {useTexts} from "./TextContext";

const PaymentModal = ({ open, onClose, value, requisites }) => {
    const {texts} = useTexts();
    const [timeLeft, setTimeLeft] = useState(process.env.PAY_TIMEOUT);
    const [showMessage, setShowMessage] = useState(false);

    useEffect(() => {
        let timer;
        let startTime = performance.now();
        let accumulatedTime = 0;

        if (open) {
            timer = setInterval(() => {
                const now = performance.now();
                accumulatedTime += now - startTime;
                startTime = now;

                const secondsElapsed = Math.floor(accumulatedTime / 1000);

                if (secondsElapsed >= timeLeft) {
                    setTimeLeft(0);
                    setShowMessage(true);
                    clearInterval(timer);
                } else {
                    setTimeLeft(process.env.PAY_TIMEOUT - secondsElapsed);
                }
            }, 1000);
        }

        return () => clearInterval(timer);
    }, [open, timeLeft]);

    useEffect(() => {
        if (!open) {
            setTimeLeft(process.env.PAY_TIMEOUT);
            setShowMessage(false);
        }
    }, [open]);

    return (
        <Modal
            open={open}
            onClose={showMessage ? onClose : null}
            disableEscapeKeyDown={!showMessage}
        >
            <Stack gap="2rem" sx={{ maxWidth: "95%", padding: '2rem', backgroundColor: 'white' }}>
                <Typography variant="h5" component="h2">{texts["payment"]["title"]}</Typography>
                <Typography dangerouslySetInnerHTML={{__html: texts["payment"]["description"]}}/>
                <CopyableText text={`${value} ₽`} label={texts["payment"]["value"]} />
                <CopyableText text={requisites} label={texts["payment"]["requisites"]} />
                {showMessage ? (
                    <></>
                ) : (
                    <Button id="modal-payment-description" disabled variant="contained">
                        {texts["payment"]["time-left"]}{timeLeft}с.
                    </Button>
                )}
            </Stack>
        </Modal>
    );
};

export default PaymentModal;
