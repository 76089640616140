import React from "react";
import { TextField } from "@mui/material";

const NumberField = (props) => {
    const handleInput = (event) => {
        const value = event.target.value;
        if (value && !/^\d+$/.test(value)) {
            event.target.value = value.replace(/\D/g, '');
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === '-' || event.key === 'e' || event.key === 'E') {
            event.preventDefault();
        }
    };

    const handleChange = (event) => {
        const value = event.target.value;
        if (value && Number(value) < 1) {
            event.target.value = 1;
        }
    };

    return (
        <TextField
            inputProps={{ min: 1 }}
            onInput={handleInput}
            onKeyDown={handleKeyDown}
            onChange={handleChange}
            {...props}
        />
    );
};

export default NumberField;
