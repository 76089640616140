import React from 'react';
import './MouseScroll.css';

const MouseScroll = () => {
    return (
        <div id="mouse-scroll">
            <div className="mouse">
                <div className="mouse-in"></div>
            </div>
            <div>
                <span className="arrow-1"></span>
                <span className="arrow-2"></span>
                <span className="arrow-3"></span>
            </div>
        </div>
    );
};

export default MouseScroll;
