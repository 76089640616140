import React, { useEffect } from "react";
import {useTheme} from "@mui/material/styles";

const loadParticlesJS = () => {
    return new Promise((resolve, reject) => {
        if (window.particlesJS) {
            resolve(window.particlesJS);
        } else {
            const script = document.createElement('script');
            script.src = '/js/particles.min.js';
            script.async = true;

            script.onload = () => {
                if (window.particlesJS) {
                    resolve(window.particlesJS);
                } else {
                    reject(new Error('particlesJS not found'));
                }
            };

            script.onerror = () => reject(new Error('Failed to load particles.js'));

            document.body.appendChild(script);
        }
    });
};

const Particles = () => {
    const theme = useTheme();

    useEffect(() => {
        loadParticlesJS().then((particlesJS) => {
            particlesJS.load('particles-js', '/particlesjs-config.json');
        }).catch((error) => {
            if (process.env.DEBUG) {
                console.error('Error loading particles.js:', error);
            }
        });
    }, []);

    return (
        <div
            id="particles-js"
            style={{
                background: theme.palette.background.default,
                position: 'absolute',
                width: '100%',
                height: '100%',
                zIndex: -1,
                top: 0,
                left: 0,
            }}
        ></div>
    );
};

export default Particles;
