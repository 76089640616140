import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

import { theme } from "./themes";
import Home from "./components/Home";
import Swift from "./components/Swift";
import VPN from "./components/VPN";
import WGGuide from "./components/WGGuide";
import { TextProvider, useTexts } from "./components/TextContext";

const AppContent = () => {
    const { loadTexts } = useTexts();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            await loadTexts();
            setIsLoading(false);
        };
        fetchData();
    }, [loadTexts]);

    if (isLoading) {
        return <div></div>;
    }

    if (process.env.DEBUG) {
        console.log(process.env)
    }

    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/swift" element={<Swift />} />
                <Route path="/vpn" element={<VPN />} />
                <Route path="/wg-guide" element={<WGGuide />} />
            </Routes>
        </Router>
    );
};

function App() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <TextProvider>
                <AppContent />
            </TextProvider>
        </ThemeProvider>
    );
}

export default App;
