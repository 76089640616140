import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useSwipeable} from "react-swipeable";
import ReactFullpage from "@fullpage/react-fullpage";
import {Grid, Stack, Box, Typography, Link} from "@mui/material";
import { Helmet, HelmetProvider } from "react-helmet-async";

// import {config} from "react-spring";
// import Carousel from "react-spring-3d-carousel";

import Particles from "./Particles";
import {useTexts} from "./TextContext";
import MouseScroll from "./MouseScroll/MouseScroll";


const Home = () => {
    const {texts} = useTexts();
    const navigate = useNavigate();
    const navLeft = useRef(null);
    const navRight = useRef(null);
    const [isLeftActive, setLeftActive] = useState(false);
    const [isRightActive, setRightActive] = useState(false);
    const [goToSlide, setGoToSlide] = useState(0);

    const handleSwipeLeft = () => {
        if (isLeftActive) {
            setLeftActive(false);
        } else {
            setRightActive(true);
        }
    };

    const handleSwipeRight = () => {
        if (isRightActive) {
            setRightActive(false);
        } else {
            setLeftActive(true);
        }
    };

    const swipeHandlers = useSwipeable({
        onSwipedLeft: handleSwipeLeft,
        onSwipedRight: handleSwipeRight,
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });

    const slides = [
        {
            key: '1',
            content: <img src="/image/psn.webp" alt="psn" style={{height: "auto"}}/>
        },
        {
            key: '2',
            content: <img src="/image/netflix.webp" alt="netflix" style={{height: "auto"}}/>
        },
        {
            key: '3',
            content: <img src="/image/steam.webp" alt="steam" style={{height: "auto"}}/>
        },
        {
            key: '4',
            content: <img src="/image/youtube.webp" alt="youtube" style={{height: "auto"}}/>
        },
        {
            key: '5',
            content: <img src="/image/figma.webp" alt="figma" style={{height: "auto"}}/>
        },
        {
            key: '6',
            content: <img src="/image/spotify.webp" alt="spotify" style={{height: "auto"}}/>
        },
    ].map((slide, index) => {
        return {...slide, onClick: () => setGoToSlide(index)};
    });

    const handleSwiftClick = (e) => {
        e.preventDefault();
        navigate('/swift');
    };

    const handleVpnClick = (e) => {
        e.preventDefault();
        navigate('/vpn');
    };

    const afterLoad = (origin, destination, direction) => {
        const sections = document.querySelectorAll('.section');
        const lastSection = sections[sections.length - 1];
        const mouseScroll = document.querySelector('#mouse-scroll');
        if (destination.item === lastSection) {
            mouseScroll.style.transform = 'translateX(-50%) rotate(180deg)';
        } else {
            mouseScroll.style.transform = 'translateX(-50%) rotate(0deg)';
        }
    };

    const handleLeftClick = () => {
        setLeftActive(true);
        setRightActive(false);
    };

    const handleRightClick = () => {
        setRightActive(true);
        setLeftActive(false);
    };

    const handleClickOutside = (event) => {
        if (navLeft.current && !navLeft.current.contains(event.target)) {
            setLeftActive(false);
        }
        if (navRight.current && !navRight.current.contains(event.target)) {
            setRightActive(false);
        }
    };

    useEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
            if (window.fullpage_api) {
                window.fullpage_api.reBuild();
            }
        });

        resizeObserver.observe(document.documentElement);

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setGoToSlide(prev => (prev + 1) % slides.length);
        }, 3000); // Change slide every 3 seconds

        return () => clearInterval(interval);
    });

    useEffect(() => {
        const handleScroll = (e) => {
            e.stopPropagation(); // Предотвращает всплытие события, что блокирует ReactFullpage
        };

        const scrollableElements = document.querySelectorAll('[data-info]');

        scrollableElements.forEach((el) => {
            el.addEventListener('wheel', handleScroll);
            el.addEventListener('touchmove', handleScroll);
        });

        return () => {
            scrollableElements.forEach((el) => {
                el.removeEventListener('wheel', handleScroll);
                el.removeEventListener('touchmove', handleScroll);
            });
        };
    }, []);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <HelmetProvider>
            <Helmet>
                <title>Cront</title>
                <meta name="description"
                      content="Удобное решение для оплаты зарубежных сервисов и покупок из России. Оформляйте подписки, обходите ограничения и оплачивайте товары в международных магазинах без проблем."/>
                <meta name="keywords"
                      content="Cront, VPN, WireGuard, оплата зарубежных сервисов, оплатить подписку из России, купить иностранную подписку, купить зарубежный товар из России, оплатить лицензию из России"/>
            </Helmet>
            <MouseScroll/>
            <Box sx={{
                zIndex: -1,
                position: "absolute",
                top: 0,
                left: 0,
                height: "100%",
                width: "100%"
            }}>
                <Box
                    component="video"
                    autoPlay
                    loop
                    muted
                    playsInline
                    poster="/fallback-image.jpg"
                    sx={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                    }}
                >
                    <source src="/bg.webm" type="video/webm"/>
                    <source src="/bg.mp4" type="video/mp4"/>
                    Your browser does not support the video tag.
                </Box>
            </Box>
            <ReactFullpage
                scrollingSpeed={1000}
                afterLoad={afterLoad}
                render={() => (
                    <ReactFullpage.Wrapper>
                        <Box component="section" className="section" {...swipeHandlers}>
                            <Grid
                                container
                                direction={{xs: "column", md: "row"}}
                                justifyContent="space-around"
                                alignItems="center" gap="10rem"
                                sx={(theme) => ({
                                    height: "100%",
                                    flexWrap: "nowrap",
                                    [theme.breakpoints.up("md")]: {
                                        justifyContent: 'space-between'
                                    }
                                })}
                            >
                                <Grid
                                    ref={navLeft}
                                    item
                                    xl={6}
                                    className={isLeftActive ? 'active' : ''}
                                    sx={{
                                        "&.active .MuiStack-root": {
                                            transform: "translateX(100%)",
                                        },
                                        "&.active .MuiLink-root": {
                                            transition: "opacity 0.5s ease-in-out",
                                            opacity: 0,
                                        },
                                    }}
                                >
                                    <Stack data-paper
                                           gap={5}
                                           justifyContent="space-between"
                                           alignItems="center"
                                           sx={(theme) => ({
                                               cursor: "auto !important",
                                               left: "-100%",
                                               [theme.breakpoints.up("md")]: {
                                                   left: "-50%"
                                               }
                                           })}
                                    >
                                        <Link
                                            href="#"
                                            onClick={handleSwiftClick}
                                            dangerouslySetInnerHTML={{__html: texts["swift"]["handle"]}}
                                            sx={{
                                                opacity: "1 !important",
                                            }}
                                        />
                                        <Typography data-info textAlign="justify" sx={{
                                            overflow: "auto"
                                        }}
                                                    dangerouslySetInnerHTML={{__html: texts["swift"]["description"]}}
                                        />
                                        {/*<Box sx={{*/}
                                        {/*    width: "100%",*/}
                                        {/*    height: "200px"*/}
                                        {/*}}>*/}
                                        {/*    <Carousel*/}
                                        {/*        slides={slides}*/}
                                        {/*        goToSlide={goToSlide}*/}
                                        {/*        offsetRadius={2}*/}
                                        {/*        showNavigation={false}*/}
                                        {/*        animationConfig={config.gentle}*/}
                                        {/*    />*/}
                                        {/*</Box>*/}
                                    </Stack>
                                    <Link
                                        href="#"
                                        variant="h3"
                                        underline="none"
                                        onClick={handleLeftClick}
                                        dangerouslySetInnerHTML={{__html: texts["swift"]["name"]}}
                                    />
                                </Grid>

                                <Grid
                                    ref={navRight}
                                    item
                                    xl={6}
                                    className={isRightActive ? 'active' : ''}
                                    sx={{
                                        textAlign: "end",
                                        "&.active .MuiStack-root": {
                                            transform: "translateX(-100%)",
                                        },
                                        "&.active .MuiLink-root": {
                                            transition: "opacity 0.5s ease-in-out",
                                            opacity: 0,
                                        },
                                    }}
                                >
                                    <Stack data-paper
                                           gap={5}
                                           alignItems="center"
                                           sx={(theme) => ({
                                               cursor: "auto !important",
                                               right: "-100%",
                                               [theme.breakpoints.up("md")]: {
                                                   right: "-50%"
                                               }
                                           })}
                                    >
                                        <Link href="#" onClick={handleVpnClick}
                                              dangerouslySetInnerHTML={{__html: texts["vpn"]["handle"]}}
                                              sx={{
                                                  opacity: "1 !important",
                                              }}
                                        />
                                        <Typography
                                            data-info
                                            textAlign="justify"
                                            sx={{
                                                overflow: "auto"
                                            }}
                                            dangerouslySetInnerHTML={{__html: texts["vpn"]["description"]}}
                                        />
                                    </Stack>
                                    <Link href="#"
                                          variant="h3"
                                          underline="none"
                                          onClick={handleRightClick}
                                          dangerouslySetInnerHTML={{__html: texts["vpn"]["name"]}}
                                    />
                                </Grid>
                            </Grid>
                        </Box>

                        <Box component="section" className="section">
                            <Particles/>
                            <Grid container justifyContent="center" alignItems="center" sx={{height: "100%"}}>
                                <Grid xl={10}>
                                    <Typography
                                        data-info
                                        dangerouslySetInnerHTML={{__html: texts["description"]}}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </ReactFullpage.Wrapper>
                )}
            />
        </HelmetProvider>
    );
};

export default Home;
